@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Condensed", sans-serif;
}

h2 {
  color: #BB2D3B;
}

a {
  text-decoration: none;
  color: black;
}

.py-6 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.py-7 {
  padding-top: 130px;
  padding-bottom: 130px;
}

/* home header start */
.header-Img h1 {
  font-size: 50px;
}

@media (min-width:700px) {
  .header-Img h1 span {
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 0.4rem;
  }
}

.header-Img .head-text {
  color: #3E3D3D;
}

.text-stroke {
  -webkit-text-stroke-color: #BB2D3B;
  -webkit-text-stroke-width: thin;
  -webkit-text-fill-color: rgb(235 236 236)
}

@media (max-width:768px) {
  .header-Img h1 {
    font-size: 50px;
  }
}

.header-overlay {
  background-color: rgba(235, 236, 236, 1);
}

/* all pages header */
.all-Header {
  background-image: url("img/all-page-headers.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.all-overlay {
  background-color: rgba(0, 0, 0, 0.7);
}

/* home page */
.product-image-container {
  overflow: hidden;
  position: relative;
}

.product-image {
  transition: transform 0.3s ease;
  height: 330px;
  width: 100%;
}

@media (max-width:768px) {
  .product-image {
    height: auto
  }
}

.product-item:hover .product-image {
  transform: scale(1.1);
}

.product-item .card {
  border: 1px solid #BB2D3B;
  align-items: center;
}

.category-img {
  height: 350px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width:1100px) and (min-width:900px) {
  .category-img {
    height: 230px;
  }
}

@media (max-width:900px) and (min-width:700px) {
  .category-img {
    height: 210px;
  }
}

.CatProduct .card {
  border: 1px solid #BB2D3B;
}

.category-text h6 {
  opacity: 0;
  transition: transform 0.5s ease;
  transform: translateY(100%);
}

.category-img:hover .category-text h6 {
  width: 100%;
  padding: 10px 0;
  opacity: 1;
  transform: translateY(0);
  color: white;
  background-color: #BB2D3B;
}

@media (max-width:568px) {
  .category-text h6 {
    width: 100%;
    padding: 10px 0;
    transform: translateY(450%);
    opacity: 1;
    color: white;
    background-color: #BB2D3B;
  }
}

.category-img:hover .category-text {
  padding: 158px 0;
}

.latest-arrivals-img {
  background-size: cover;
  background-repeat: no-repeat;
}

.latest-product .card {
  border: 1px solid #BB2D3B;
}

.latest-product h5 {
  font-size: 11px;
  margin-top: 8px;
}

.latest-product h5,
.latest-product h6 {
  color: rgb(134, 134, 134);
}

/* about page */
.about-card {
  width: 250px;
  background-color: rgba(229, 229, 229, 0.7);
  padding: 20px;
}

/* navbar */
.navbar i {
  color: white;
}

@media (max-width:968px) {
  .navbar i {
    color: white;
    margin-left: -12px;
  }
}

.home-bar {
  background: linear-gradient(-75deg, black 30%, #BB2D3B 30%);
}

.home-bar i {
  font-size: 20px;
}

@media (min-width:600px) {

  .navbar.scrolled {
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 2;
  }
}

/* call to action */
.cal-bg {
  background-image: url(./img/cal-to-action-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}

.call-to-action {
  background-color: rgba(235, 236, 236, .93);
  color: #3E3D3D;
  padding: 0;
}

.call-to-action h2 {
  background-color: #BB2D3B;
  color: white;
  padding: 8px;
  border-radius: 20px;
  display: inline-block;
  font-size: 20px;
  font-weight: lighter;
}

/* footer */
.footer,
.topBar {
  background-color: rgb(235 236 236);
}

.footer h2 {
  color: #BB2D3B;
}

.footer-bottom {
  background-color: #BB2D3B;
  padding: 10px;
  border-radius: 50px;
}

.footer-redish {
  background-color: #BB2D3B;
  border-radius: 20px;
}

.footer-links i {
  color: #BB2D3B;
  font-size: 13px;
}

/* contact  */
.contact i {
  color: #BB2D3B;
}

/* testimonial */
.slider-container {
  background-color: rgba(235, 236, 236, .92);
}

.profile-set {
  position: absolute;
  top: 14%;
  right: 1%;
}

.profile-set h3 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #BB2D3B;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  color: white;
}

.profile-set a {
  color: #BB2D3B;
}

/* latest css */
@media (min-width:600px) {
  .card .product-image {
    width: 100%;
  }
}

.product-logos {
  position: absolute;
  top: 30%;
  right: 0;
  background-color: #BB2D3B;
  color: white;
  padding: 20px;
  opacity: 0;
  transition: opacity 3s ease;
}

.productCard {
  color: initial;
}

.productCard:hover .product-logos {
  opacity: 1;
  transition: opacity 0.7s ease;
}

.catCollection i {
  font-size: 40px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 10px #ffdde1;
  padding: 30px;
}

.aboutSec li {
  line-height: 2rem;
  list-style: none;
}

.aboutSec li i {
  color: #BB2D3B;
}

.topBar p {
  margin-bottom: 0;
}

.topBar i {
  color: #BB2D3B;
}

.emailDiv {
  margin-left: 10px;
}

.contactInfo h6 {
  font-weight: 400;
}

.social-links strong {
  line-height: 2.5rem;
}

.card {
  height: 100%;
}

/* review */
.reviewBody {
  position: relative;
}

.reviewTime {
  position: absolute;
  top: 9%;
  right: 2%;
}

/* navbar cart */

.profileSet {
  position: absolute;
  right: 2%;
  top: 80%;
}

a,
i {
  cursor: pointer;
}

.productDetail-img {
  height: 60px;
  width: 100%;
}

@media (max-width:600px) {
  .productDetail-img {
    height: 50px;
    width: 100%;
  }
}